var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.$store.state.form.edit,staticClass:"flix-flex flix-gap-5",staticStyle:{"flex-direction":"column"}},[_c('div',{staticClass:"flix-flex flix-flex-center",staticStyle:{"padding":"5px","padding-bottom":"0","flex-direction":"column"}},[_c('a',{staticClass:"flix-btn flix-btn-xs",class:{ 'flix-btn-invert': _vm.$store.state.form.edit === _vm.id },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setOpen.apply(null, arguments)}}},[_c('icon',{attrs:{"id":"brush"}})],1)]),(_vm.$store.state.form.edit === _vm.id)?_c('div',{staticClass:"flix-flex flix-flex-center flix-gap-5 flix-border-grey",staticStyle:{"border-width":"1px","border-style":"solid","padding":"5px","flex-direction":"column"}},[(_vm.$store.state.form.edit === _vm.id && _vm.id > 0)?_c('a',{staticClass:"flix-btn flix-btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setPosition(-1)}}},[_c('icon',{attrs:{"id":"chevron-up"}})],1):_vm._e(),(_vm.$store.state.form.edit === _vm.id && _vm.checkRequired())?_c('a',{staticClass:"flix-btn flix-btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setCopy.apply(null, arguments)}}},[_c('icon',{attrs:{"id":"copy-duplicate"}})],1):_vm._e(),(
        _vm.$store.state.form.edit === _vm.id &&
          _vm.$store.state.business.unsaved.form.length > 1 &&
          _vm.checkRequired()
      )?_c('a',{staticClass:"flix-btn flix-btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setDelete.apply(null, arguments)}}},[_c('icon',{attrs:{"id":"bin"}})],1):_vm._e(),(
        _vm.$store.state.form.edit === _vm.id &&
          _vm.id < _vm.$store.state.business.unsaved.form.length - 1
      )?_c('a',{staticClass:"flix-btn flix-btn-xs",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setPosition(1)}}},[_c('icon',{attrs:{"id":"chevron-down"}})],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }