<template>
  <div
    class="flix-flex flix-gap-5"
    style="flex-direction: column"
    :key="$store.state.form.edit"
  >
    <div
      class="flix-flex flix-flex-center"
      style="padding: 5px; padding-bottom: 0; flex-direction: column"
    >
      <a
        href="#"
        class="flix-btn flix-btn-xs"
        :class="{ 'flix-btn-invert': $store.state.form.edit === id }"
        @click.prevent="setOpen"
        ><icon id="brush"
      /></a>
    </div>
    <div
      class="flix-flex flix-flex-center flix-gap-5 flix-border-grey"
      v-if="$store.state.form.edit === id"
      style="border-width: 1px; border-style: solid; padding: 5px; flex-direction: column"
    >
      <a
        href="#"
        class="flix-btn flix-btn-xs"
        v-if="$store.state.form.edit === id && id > 0"
        @click.prevent="setPosition(-1)"
        ><icon id="chevron-up"
      /></a>
      <a
        href="#"
        class="flix-btn flix-btn-xs"
        v-if="$store.state.form.edit === id && checkRequired()"
        @click.prevent="setCopy"
        ><icon id="copy-duplicate"
      /></a>
      <a
        href="#"
        class="flix-btn flix-btn-xs"
        v-if="
          $store.state.form.edit === id &&
            $store.state.business.unsaved.form.length > 1 &&
            checkRequired()
        "
        @click.prevent="setDelete"
        ><icon id="bin"
      /></a>
      <a
        href="#"
        class="flix-btn flix-btn-xs"
        v-if="
          $store.state.form.edit === id &&
            id < $store.state.business.unsaved.form.length - 1
        "
        @click.prevent="setPosition(1)"
        ><icon id="chevron-down"
      /></a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: Number
  },
  data() {
    return {
      open: this.$store.state.form.edit
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setPosition(add) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form.splice(this.id + add, 0, data.form.splice(this.id, 1)[0]);
      this.$store.commit("business/prefetch", data);
      this.$store.commit("form/setEdit", this.id + add);
    },
    checkRequired() {
      if (
        this.$store.state.business.unsaved.form[this.id].subtype === "email" ||
        this.$store.state.business.unsaved.form[this.id].type === "email"
      ) {
        return false;
      }
      if (this.$store.state.business.unsaved.form[this.id].type === "terms") {
        return false;
      }
      return true;
    },
    setDelete() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.form.splice(this.id * 1, 1);

      this.$store.commit("business/prefetch", data);
      this.$store.commit("form/setEdit", false);
    },
    setCopy() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      var copy = JSON.parse(JSON.stringify(data.form))[this.id * 1];
      copy.id = new Date().getTime();
      data.form.splice(this.id * 1 + 1, 0, copy);
      this.$store.commit("business/prefetch", data);
      this.$store.commit("form/setEdit", this.id * 1 + 1);
    },
    setOpen() {
      if (this.id === this.$store.state.form.edit) {
        this.$store.commit("form/setEdit", false);
      } else {
        this.$store.commit("form/setEdit", this.id);
      }
    }
  }
};
</script>
<style lang="sass" scoped></style>
